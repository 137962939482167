import React, {useState, useMemo, useEffect} from 'react';
import { useHistory } from "react-router-dom";
import Tada from 'react-reveal/Tada';
import Jump from 'react-reveal/Jump';
import { Container, Row, Col, Image, ListGroup, InputGroup } from 'react-bootstrap';
import {LinkContainer} from 'react-router-bootstrap';
import Checkbox from "react-custom-checkbox";
import * as Icon from "react-icons/im";
import { BsFillCaretLeftFill} from "react-icons/bs";
import Modal3 from './components/Modal3'
import axios from 'axios';
import mailchimpFactory from '@mailchimp/mailchimp_transactional/src/index.js';
import countryList from 'react-select-country-list';
const mailchimp = mailchimpFactory("3MaihbLvVMRazJCeofwVyw");


const Inscription = ({props, ChangeActive, sendColorbg, sendColor}) => {

  const history = useHistory();


    const color1 = "#007F8B";
    const color2 = "#988EFA";
    const [sujet, setSujet] = useState('');
    const [msg, setMsg] = useState('');
    const [colorbg, setcolorbg] = useState("white");
    const [color, setcolor] = useState("black");
    const [Active, setActive] = useState('/');
    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);
    const [oeuf, setoeuf] = useState('');
    const [Nom, setNom] = useState('');
    const [Prenom, setPrenom] = useState('');
    const [Mail, setMail] = useState('');
    const [sexe, setSexe] = useState('');
    const [Profession, setProfession] = useState('');
    const [Metier, setMetier] = useState('');
    const [Special, setSpecial] = useState('');
    const [Exercice, setExercice] = useState(-1);
    const [phone, setPhone] = useState('');
    const [rue, setRue] = useState('');
    const [numRue, setnumRue] = useState('');
    const [compAd, setcompAd] = useState('');
    const [codepostale, setCodepostale] = useState('');
    const [ville, setVille] = useState('');
    const [value, setValue] = useState('FR')
    const [value2, setValue2] = useState('FR')
    const [Pays, setPays] = useState('France')
    const [national, setNational] = useState('France')
    const {overwrite, getName } = require('country-list');
    const [startDate, setStartDate] = useState('');
    const [BDate, setBDate] = useState('');
    const [disabledcolor, setdisabledcolor] = useState('#DCDCDC');
    const [disabled, setdisabled] = useState(false);
    const [disabledcolor2, setdisabledcolor2] = useState('#DCDCDC');
    const [disabled2, setdisabled2] = useState(false);
    const URL = 'https://www.useradventure.fr/request/'
    const [checked, setChecked] = useState(false);
    const [checked2, setChecked2] = useState(true);
    const [data, setData] = useState([]);
    const URL_ville =  "https://vicopo.selfbuild.fr/cherche/"
    const [check1, setcheck1] = useState(false);
    const [check2, setcheck2] = useState(false);
    const [dataVille, setDataVille] = useState([]);
    const [modalVisible3, setModalVisible3] = useState(false);

    useEffect(()=>{
      sendColorbg('white')
      sendColor("black")
      }, [props])
    
  
    const optionSexe = [
      { value: '', label: 'Selectionner...' },
      { value: 1, label: 'Homme' },
      { value: 2, label: 'Femme' },
     // { value: 3, label: `Je m'identifie comme un homme` },
     // { value: 4, label: `Je m'identifie comme une femme` },
      { value: 5, label: `Je ne m'identifie à aucun genre` }
    ]

    const optionMetier = [
      { value: '', label: 'Selectionner...' },
      { value: 'Professionnel de santé', label: 'Professionnel de santé' },
      { value: 'Agriculteur', label: 'Agriculteur' },
      { value: `Artisan`, label: `Artisan` },
      { value: `Commerçant`, label: `Commerçant` },
      { value: `Chef d'entreprise`, label: `Chef d'entreprise` },
      { value: `Cadre et profession Intellectuelle supérieure`, label: `Cadre et profession Intellectuelle supérieure` },
      { value: `Professions Intermédiaires`, label: `Professions Intermédiaires` },
      { value: `Employé`, label: `Employé` },
      { value: `Ouvrier`, label: `Ouvrier` },
      { value: `Etudiant`, label: `Etudiant` },
      { value: `Inactif ou chômeur n'ayant jamais travaillé`, label: `Inactif ou chômeur n'ayant jamais travaillé` },
    ]

    const options = useMemo(() => countryList().getData(), [])

  
      
      const ChangeCompAd = (event) => {
        setcompAd(event)
       console.log(event)
      };
      
      const ChangePhone = (event) => {
        setPhone(event)
        if(Profession === 'Professionnel de santé'){
          if(event !== '' && Profession !=='' && Metier !=='' && Special !=='' && Exercice !== -1 && numRue !=='' && rue !==''&& codepostale !==''  && ville !=='' && Pays !==''  && checked === true){
          setdisabledcolor2('#febf01')
          setdisabled2(true)
        } else {
          setdisabledcolor2('#DCDCDC')
          setdisabled2(false)
        }
        }else {
          if(event !== '' && Profession !=='' && Metier !=='' && numRue !=='' && rue !==''&& codepostale !==''  && ville !=='' && Pays !==''  && checked === true){
            setdisabledcolor2('#febf01')
            setdisabled2(true)
          } else {
            setdisabledcolor2('#DCDCDC')
            setdisabled2(false)
          }
        }
       
       console.log(event)
      };

      const ChangeProfession = (event) => {
        setProfession(event)
        if(event === 'Professionnel de santé'){
          console.log('rr')
        if(event !== '' && phone !=='' && Metier !=='' && Special !=='' && Exercice !== -1 && numRue !=='' && rue !==''&& codepostale !=='' && ville !=='' && Pays !==''  && checked === true ){
          setdisabledcolor2('#febf01')
          setdisabled2(true)
          console.log('aa')
        } else {
          setdisabledcolor2('#DCDCDC')
          setdisabled2(false)
          console.log('oo')
        }
      }else {
        if(event !== '' && phone !=='' && Metier !=='' && numRue !=='' && rue !=='' && codepostale !=='' && ville !=='' && Pays !==''  && checked === true ){
          setdisabledcolor2('#febf01')
          setdisabled2(true)
        } else {
          setdisabledcolor2('#DCDCDC')
          setdisabled2(false)
        }


      }
       console.log(event)
      };

      const ChangeMetier = (event) => {
        setMetier(event)
        if(Profession === 'Professionnel de santé'){
        if(event !== '' && phone !=='' && Profession !=='' && Special !=='' && Exercice !== -1 && codepostale !=='' && rue !=='' && ville !=='' && Pays !=='' && numRue !=='' && checked === true){
          setdisabledcolor2('#febf01')
          setdisabled2(true)
        } else {
          setdisabledcolor2('#DCDCDC')
          setdisabled2(false)
        } }else {
          if(event !== '' && phone !=='' && Profession !=='' && codepostale !=='' && rue !=='' && ville !=='' && Pays !=='' && numRue !=='' && checked === true){
            setdisabledcolor2('#febf01')
            setdisabled2(true)
          } else {
            setdisabledcolor2('#DCDCDC')
            setdisabled2(false)
          }
        }
       console.log(event)
      };

      const ChangeSpecial = (event) => {
        setSpecial(event)
        if(Profession === 'Professionnel de santé'){
          if(event !== '' && phone !=='' && Profession !=='' && Metier !=='' && Exercice !== -1 && codepostale !=='' && rue !=='' && ville !=='' && Pays !=='' && numRue !=='' && checked === true){
            setdisabledcolor2('#febf01')
            setdisabled2(true)
          } else {
            setdisabledcolor2('#DCDCDC')
            setdisabled2(false)
          }
        }
       console.log(event)
      };

      const Changecheck1 = (event) => {
          setcheck1(!check1)
          const Exercice = event && check2  ? 3 : event ? 1 : check2 ? 2 : -1;
          setExercice(Exercice)
          if(Profession === 'Professionnel de santé'){
            if((event || check2)  && phone !=='' && Profession !=='' && Metier !=='' && Special !=='' && codepostale !=='' && rue !=='' && ville !=='' && Pays !=='' && numRue !=='' && checked === true){
          setdisabledcolor2('#febf01')
          setdisabled2(true)
        } else {
          setdisabledcolor2('#DCDCDC')
          setdisabled2(false)
        }
      }
      console.log(Exercice)
      };

      

      const Changecheck2 = (event) => {
        setcheck2(!check2)
        const Exercice = check1 && event  ? 3 : check1 ? 1 : event ? 2 : -1;
        setExercice(Exercice)
        if(Profession === 'Professionnel de santé'){
        if((event || check1) && phone !=='' && Profession !=='' && Metier !=='' && Special !=='' && codepostale !=='' && rue !=='' && ville !=='' && Pays !=='' && numRue !=='' && checked === true){
          setdisabledcolor2('#febf01')
          setdisabled2(true)
        } else {
          setdisabledcolor2('#DCDCDC')
          setdisabled2(false)
        }
      }
      console.log(Exercice)
      };
      
       const ChangenumRue = (event) => {
        setnumRue(event)
        if(Profession === 'Professionnel de santé'){
        if(event !== '' && phone !=='' && Profession !=='' && Metier !=='' && Special !=='' && Exercice !== -1 && rue !=='' && codepostale !=='' && ville !=='' && Pays !=='' && checked === true){
          setdisabledcolor2('#febf01')
          setdisabled2(true)
        } else {
          setdisabledcolor2('#DCDCDC')
          setdisabled2(false)
        } } else {
          if(event !== '' && phone !=='' && Profession !=='' && Metier !=='' && rue !=='' && codepostale !=='' && ville !=='' && Pays !=='' && checked === true){
            setdisabledcolor2('#febf01')
            setdisabled2(true)
          } else {
            setdisabledcolor2('#DCDCDC')
            setdisabled2(false)
          }
        }
       console.log(event)
      };

      const ChangeRue = (event) => {
        setRue(event)
        if(Profession === 'Professionnel de santé'){
        if(event !== '' && phone !=='' && Profession !=='' && Metier !=='' && Special !=='' && Exercice !== -1 && numRue !=='' && codepostale !=='' && ville !=='' && Pays !==''  && checked === true){
          setdisabledcolor2('#febf01')
          setdisabled2(true)
        } else {
          setdisabledcolor2('#DCDCDC')
          setdisabled2(false)
        } } else {
          if(event !== '' && phone !=='' && Profession !=='' && Metier !=='' && numRue !=='' && codepostale !=='' && ville !=='' && Pays !==''  && checked === true){
            setdisabledcolor2('#febf01')
            setdisabled2(true)
          } else {
            setdisabledcolor2('#DCDCDC')
            setdisabled2(false)
          }
        }
       console.log(event)
      };


        const ChangeCodepostale = (event) => {
          dataVille.length = 0
          setModalVisible3(false)
          axios.get(`${URL_ville}${event}`).then(function(response){
              response.data.cities.map((item, index) => {
                dataVille.push({ key: index++,"label":item.city})
               // setData([...data,{ key: index++,"label":item.city}])
               console.log(dataVille)
              })
             
               })
              .catch(function (error) {
                // handle error
                console.log(error);
              })
              .finally(() =>  {
             

        setCodepostale(event)
        if(Profession === 'Professionnel de santé'){
        if(event !== '' && phone !=='' && Profession !=='' && Metier !=='' && Special !=='' && Exercice !== -1 && numRue !=='' && rue !=='' && ville !=='' && Pays !==''  && checked === true){
          setdisabledcolor2('#febf01')
          setdisabled2(true)
        } else {
          setdisabledcolor2('#DCDCDC')
          setdisabled2(false)
        }} else {
          if(event !== '' && phone !=='' && Profession !=='' && Metier !=='' && numRue !=='' && rue !=='' && ville !=='' && Pays !==''  && checked === true){
            setdisabledcolor2('#febf01')
            setdisabled2(true)
          } else {
            setdisabledcolor2('#DCDCDC')
            setdisabled2(false)
          }
        }
       console.log(event)})
      };


      const ChangeVille = (event) => {
        setVille(event)
        if(Profession === 'Professionnel de santé'){
        if(event !== '' && phone !=='' && Profession !=='' && Metier !=='' && Special !=='' && Exercice !== -1 && numRue !=='' && rue !=='' && codepostale !=='' && Pays !==''  && checked === true){
          setdisabledcolor2('#febf01')
          setdisabled2(true)
        } else {
          setdisabledcolor2('#DCDCDC')
          setdisabled2(false)
        }} else { if(event !== '' && phone !=='' && Profession !=='' && Metier !=='' && numRue !=='' && rue !=='' && codepostale !=='' && Pays !==''  && checked === true){
          setdisabledcolor2('#febf01')
          setdisabled2(true)
        } else {
          setdisabledcolor2('#DCDCDC')
          setdisabled2(false)
        }
      }
       console.log(event)
      };



      const changeHandler2 = value => {
         //Pays
        setValue2(value)
        setPays(getName(value))
        if(Profession === 'Professionnel de santé'){
        if(value.value !== '' && phone !=='' && Profession !=='' && Metier !=='' && Special !=='' && Exercice !== -1 && rue !=='' && codepostale !=='' && ville !=='' && numRue !=='' && checked === true ){
          setdisabledcolor2('#febf01')
          setdisabled2(true)
        } else {
          setdisabledcolor2('#DCDCDC')
          setdisabled2(false)
        }} else {
          if(value.value !== '' && phone !=='' && Profession !=='' && Metier !=='' && rue !=='' && codepostale !=='' && ville !=='' && numRue !=='' && checked === true ){
            setdisabledcolor2('#febf01')
            setdisabled2(true)
          } else {
            setdisabledcolor2('#DCDCDC')
            setdisabled2(false)
          }
        }
       //console.log(getName(value.value))
      }

      const handleChange = () => {
        setChecked(!checked);
        if(Profession === 'Professionnel de santé'){
        if(!checked === true && phone !=='' && Profession !=='' && Metier !=='' && Special !=='' && Exercice !== -1 && numRue !=='' && rue !=='' && codepostale !=='' && ville !=='' && Pays !==''){
          setdisabledcolor2('#febf01')
            setdisabled2(true)
          } else {
            setdisabledcolor2('#DCDCDC')
            setdisabled2(false)
          }} else {
            if(!checked === true && phone !=='' && Profession !=='' && Metier !=='' && numRue !=='' && rue !=='' && codepostale !=='' && ville !=='' && Pays !==''){
              setdisabledcolor2('#febf01')
                setdisabled2(true)
              } else {
                setdisabledcolor2('#DCDCDC')
                setdisabled2(false)
              }
            }
        console.log((!checked))
      };


      const handleChange2 = () => {
        setChecked2(!checked2);
        console.log(checked2)
      };
      
      const ChangeDate = (event) => {
        ///const moonLanding = new Date(event);
        setStartDate(event)
        setBDate(event)

        if(event !== '' && Nom !=='' && sexe !=='' && Prenom !=='' && value !=='' && Mail !==''){
          setdisabledcolor('#febf01')
          setdisabled(true)
        } else {
          setdisabledcolor('#DCDCDC')
          setdisabled(false)
        }
       console.log(event)
      };
      
      const changeHandler = value => {
        setValue(value)
        setNational(getName(value))
        if(value.value !== '' && Nom !=='' && startDate !=='' && Prenom !=='' && sexe !=='' && Mail !==''){
          setdisabledcolor('#febf01')
          setdisabled(true)
        } else {
          setdisabledcolor('#DCDCDC')
          setdisabled(false)
        }
        //console.log(getName(value.value))
      }
      const ChangeGenre = (event) => {
        setSexe(event)
        if(event !== '' && Nom !=='' && startDate !=='' && Prenom !=='' && value !=='' && Mail !==''){
          setdisabledcolor('#febf01')
          setdisabled(true)
        } else {
          setdisabledcolor('#DCDCDC')
          setdisabled(false)
        }
       console.log(event)
      };

      
      
      const ChangeNom = (event) => {
        setNom(event)
        if(event !== '' && Prenom !=='' && startDate !=='' && sexe !=='' && value !=='' && Mail !==''){
          setdisabledcolor('#febf01')
          setdisabled(true)
        } else {
          setdisabledcolor('#DCDCDC')
          setdisabled(false)
        }
       console.log(event)
      };
      
      const ChangePrenom = (event) => {
        setPrenom(event)
        if(event !== '' && Nom !=='' && startDate !=='' && sexe !=='' && value !=='' && Mail !==''){
          setdisabledcolor('#febf01')
          setdisabled(true)
        } else {
          setdisabledcolor('#DCDCDC')
          setdisabled(false)
        }
       console.log(event)
      };
      
      const ChangeMail = (event) => {
        setMail(event)
        if(event !== '' && Nom !==''  && Prenom !=='' && startDate !=='' && sexe !=='' && value !==''){
          setdisabledcolor('#febf01')
          setdisabled(true)
        } else {
          setdisabledcolor('#DCDCDC')
          setdisabled(false)
        }
       console.log(event)
      };
      
      
      function validCommand() {
        //setShow(false);
        var position = Mail.indexOf("@");
  var reste = Mail.substr(-position);
  var posit = reste.indexOf(".");
  //alert(posit)
  if(position !== -1 && posit !== -1 ){
        if(disabled){
          fetch(`${URL}json_get_react_find_mail_manu.php`, {
            method: 'post',
            headers: {
              'Accept': 'application/json',  // It can be used to overcome cors errors
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ user_name:Mail   })
          })
            .then((response) => response.json())
            .then(response => { 
              console.log(response)
              if(response.length === 0){
        setShow1(true)}  else {
          alert(`l'adresse mail que vous avez choisi existe déjà`)
        }})
        }else{
          alert('Veuillez remplir tous les champs, Merci!')
        }
        console.log(Nom, Prenom, sexe, value, startDate)
      }else{
        alert('Email non valide')
      }
      }

      const run = async (message) => {
        const response = await mailchimp.messages.sendTemplate({
          template_name: "SendRegisterSite",
          "template_content": [ {}],
          message: message,
        });
        console.log(response);
      };

      function validCommand1() {
        if(disabled2){
          //setShow(false);
          setShow1(false);
          
          fetch(`${URL}siteUA_json_input_register_site.php`, {
            method: 'post',
            headers: {
              'Accept': 'application/json',  // It can be used to overcome cors errors
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({name:Nom,
                                  surname:Prenom,
                                  genre:sexe,
                                  pays:Pays,
                                  bday:BDate,
                                  mail:Mail,
                                  country:value2,
                                  phone:phone,
                                  profession:Profession,
                                  metier:Metier,
                                  special:Special,
                                  exercice:Exercice,
                                  numrue:numRue,
                                  rue:rue,
                                  postal:codepostale,
                                  ville:ville,
                                  adressup:compAd,
                                  national:national,
                                  newsletter:checked2
                                })
          })
            .then((response) => response.json())
            .then(() => {
              const message = {
                from_name: "UserAdventure",
                from_email: "contact@useradventure.fr",
                subject: `Confirmation de votre compte UserAdventure`,
                to: [
                  {
                    email: Mail,
                    type: "to"
                  }
                ],
                "merge_vars": [
                  {
                      "rcpt": Mail,
                      "vars": [
                        {
                          "name": "ID1",
                          "content": `https://www.useradventure.net/siteUA_comfirmation_compte_site.php?Email=${Mail}`
                        },
                        {
                          "name": "SURNAME",
                          "content": Prenom
                        }
                      ]
                  }
              ]
              }
            run(message)
            setShow2(true)
            setNom('')
            setPrenom('')
            setMail('')
            setSexe('')
            setValue('FR')
            setStartDate('')
            setPhone('')
            setnumRue('')
            setRue('')
            setcompAd('')
            setCodepostale('')
            setVille('')
            setPays('')
            setProfession('')
            setMetier('')
            setSpecial('')
            setValue2('FR')
          })
            .catch((error) => console.error(error))
            
          }else{
            alert('Veuillez remplir tous les champs, Merci!')
          }
       
        console.log(Nom, Prenom, sexe, value, startDate, phone, Profession, Metier, Special, Exercice, numRue, rue, compAd, codepostale, ville, Pays, checked )
        
      }
      
      function validCommand2() {
      
        setShow1(false);
      }

      function validCommand3() {
        setShow2(false);
        history.push("/")
        window.scrollTo(0, 0)
      }

    return (
        <div>
     { !show1 ? <Container fluid style={{background: `linear-gradient(to bottom,  ${color1} 0%,${color2} 100%)`,paddingTop:150, paddingBottom : 30}}>
        <Col md={{ span: 10, offset:1  }} lg={{ span: 4, offset: 4 }} style={{marginTop:10,background: 'rgba(0, 0, 0, 0.3)',borderRadius:50}}>
        <Jump>
          <Col className="text-center" style={{paddingTop:30,color:'white', fontWeight:'bold',fontSize:30}}>
            Inscription
          </Col>
          </Jump>
          <Col className="text-center" style={{color:'white', marginTop:50,fontWeight:'bold',fontSize:30,marginInline:20}}>
          Afin de pouvoir participer aux études, merci de compléter le formulaire ci-dessous
          </Col>
          <Col className="text-center" style={{color:'white', marginTop:50,fontSize:20}}>
            * Champ obligatoire
          </Col>
          
          
         <div class="form-group" style={{marginInline:20}}>
                             <label class="form-label mt-4"></label>
                            <div class="form-floating mb-3">
                                <input type="text" id="Nom" name="Nom" required value={Nom} onChange={e =>ChangeNom(e.target.value)} style={{borderRadius:30}} class="form-control" placeholder="name@example.com"/>
                                <label for="Nom">Nom *</label>
                            </div>
                          <div class="form-floating mb-3">
                                <input type="text" id="Prenom" name="Prenom" required value={Prenom} onChange={e =>ChangePrenom(e.target.value)} style={{borderRadius:30}} class="form-control" placeholder="floatingPrenom"/>
                                <label for="Prenom">Prénom *</label>
                            </div>
                            <div class="form-floating mb-3">
                                <input type="email" id="email" required value={Mail} onChange={e =>ChangeMail(e.target.value)} style={{borderRadius:30}} class="form-control"  placeholder="floatingPrenom"/>
                                <label for="email">Email *</label>
                            </div>
                            <div class="form-floating mb-3" >
                                <select required onChange={e =>ChangeGenre(e.target.value)} value={sexe} style={{borderRadius:30}} placeholder="Genre *" class="form-control" id="floatinggenre">
                                {
                                optionSexe.map( (x) => 
                                  <option key={x.value} value={x.value}>{x.label}</option> )
                                }
                                </select>
                                <label for="floatingPrfloatinggenreenom">Genre *</label>
                            </div>
                            
                            <div class="form-floating mb-3" >
                                <select onChange={e =>changeHandler(e.target.value)} value={value} style={{borderRadius:30}} placeholder="Nationalité *" class="form-control" id="floatinggenre">
                                {
                                options.map( (x) => 
                                  <option key={x.value} value={x.value} label={x.label}>{x.label}</option> )
                                }
                                </select>
                                <label for="floatingPrfloatinggenreenom">Nationalité *</label>
                            </div>
                            <div class="form-floating mb-3">
                                <input type="date" value={startDate} data-date-format="DD MMMM YYYY"  min="1900-01-01"  onChange={e =>ChangeDate(e.target.value)} style={{borderRadius:30}} class="form-control" id="start" name="trip-start"/>
                                <label for="start">Date de naissance *</label>
                            </div>
                            <Tada>
                            <Row style={{justifyContent:'center',alignItems:'center',marginTop:30}}>
        <Col  className="text-center" >
        <a role="button" class="btn" onClick={validCommand} style={{background:'transparent',color:'white'}} >
                    <Row style={{alignItems:'center'}}>
                        <Col xs="4" style={{zIndex:2}}> 
                            <Image src="https://useradventure.net/images/pigeon.svg"  style={{height:115}}/>
                        </Col>
                        <Col xs="8" style={{zIndex:1}}>
                            <Row style={{background: disabledcolor, borderRadius:30, paddingBlock:20, paddingLeft:40,paddingRight:15, justifyContent:'center',marginLeft:-40, marginTop:-30, fontWeight:'bold', fontSize:18}}>Suivant</Row>
                        </Col>
                    </Row>
              </a> 
        </Col>
      </Row>
              </Tada>          
                  </div>
        </Col> 
        </Container>:
        <Container fluid style={{background: `linear-gradient(to bottom,  ${color1} 0%,${color2} 100%)`,paddingTop:150, paddingBottom : 30}}>
        <Col md={{ span: 10, offset:1  }} lg={{ span: 4, offset: 4 }} style={{marginTop:10,background: 'rgba(0, 0, 0, 0.3)',borderRadius:50}}>
        <a role="button" class="btn" style={{color:'white', fontSize:20, borderWidth:1, borderColor:'white', borderRadius:20, marginLeft:20, marginTop:20, cursor:'pointer' }} onClick={validCommand2}>
        <BsFillCaretLeftFill/>Précédent
        </a>
        <Jump>
          <Col className="text-center" style={{paddingTop:10,color:'white', fontWeight:'bold',fontSize:30}}>
            Inscription
          </Col>
          </Jump>
          <Col className="text-center" style={{color:'white', marginTop:50,fontWeight:'bold',fontSize:30}}>
          Encore quelques petites informations...
          </Col>
          <Col className="text-center" style={{color:'white', marginTop:50,fontSize:20,marginInline:20}}>
          Ces informations nous permettrons d'une part, de favoriser l'organisation de certaines études mais également de vous transmettre vos gains par voie postale (chèque).
          </Col>
          
          
          <div class="form-group" style={{marginInline:20}}>
                             <label class="form-label mt-4"></label>
                            <div class="form-floating mb-3">
                                <input type="tel" id="phone" name="phone" required style={{borderRadius:30}}  value={phone} onChange={e =>ChangePhone(e.target.value)} class="form-control" placeholder="floatingPrenom"/>
                                <label for="phone">Téléphone portable *</label>
                            </div>
                            <div class="form-floating mb-3" >
                                <select required onChange={e =>ChangeProfession(e.target.value)} value={Profession} style={{borderRadius:30}} placeholder="Profession *" class="form-control" id="floatinggenre">
                                {
                                  optionMetier.map( (x) => 
                                  <option key={x.value} value={x.value}>{x.label}</option> )
                                }
                                </select>
                                <label for="floatingPrfloatinggenreenom">Profession *</label>
                            </div>

                            <div class="form-floating mb-3"> 
                                <input type="text" id="metier" name="metier" required style={{borderRadius:30}}  value={Metier} onChange={e =>ChangeMetier(e.target.value)} class="form-control" placeholder="floatingPrenom"/>  
                                <label for="metier">Métier *</label>
                          </div>
                           
                          {  Profession === 'Professionnel de santé' ? <>
                          <div class="form-floating mb-3"> 
                                <input type="text" id="special" name="special" required style={{borderRadius:30}}  value={Special} onChange={e =>ChangeSpecial(e.target.value)} class="form-control" placeholder="floatingPrenom"/>  
                                <label for="special">Spécialité *</label>
                          </div>
                         
                          <Col  style={{color:'white', fontWeight:500,fontSize:18, paddingBottom:10}}>Vous exercez : *</Col>

                      <Checkbox
        icon={<Icon.ImCheckmark color='#007984' size={16} />}
        name="my-input1"
        checked={check1}
        onChange={()=>{Changecheck1(!check1)}}
        borderColor='#007984'
        borderRadius={2}
        borderWidth={1}
        style={{ cursor: "pointer" , background:'white'}}
        labelStyle={{ marginLeft: 5, userSelect: "none", color:'white', fontWeight:500 }}
        label='Dans le privé'
      />  
       <Checkbox
        icon={<Icon.ImCheckmark color='#007984' size={16} />}
        name="my-input2"
        checked={check2}
        onChange={()=>{Changecheck2(!check2)}}
        borderColor='#007984'
        borderRadius={2}
        borderWidth={1}
        style={{ cursor: "pointer", background:'white' }}
        labelStyle={{ marginLeft: 5, userSelect: "none", color:'white', fontWeight:500 }}
        label='Dans le public'
      />
                          </> : undefined}
                            <Col className="text-center"  style={{color:'white', fontWeight:500,fontSize:18, paddingBottom:10}}>Adresse :</Col>
                            <div class="form-floating mb-3">
                                <input type="text" id="numrue" name="numrue" required style={{borderRadius:30}} value={numRue} onChange={e =>ChangenumRue(e.target.value)}  class="form-control" placeholder="floatingPrenom"/>
                                <label for="numrue">Numéro de voie*</label>
                            </div>
                            <div class="form-floating mb-3">
                                <input type="text" id="rue" name="rue" required style={{borderRadius:30}} value={rue} onChange={e =>ChangeRue(e.target.value)}  class="form-control" placeholder="floatingPrenom"/>
                                <label for="rue">Nom de rue *</label>
                            </div>
                            <div class="form-floating mb-3">
                                <input type="text" id="compl" name="compl" required style={{borderRadius:30}} value={compAd} onChange={e =>ChangeCompAd(e.target.value)}  class="form-control" placeholder="floatingPrenom"/>
                                <label for="compl">Complément d'adresse</label>
                            </div>
                            <div class="form-floating mb-3">
                                <input type="text" id="postal" name="postal" required style={{borderRadius:30}} value={codepostale}  onChange={e =>ChangeCodepostale(e.target.value)} class="form-control" placeholder="floatingPrenom"/>
                                <label for="postal">Code postal *</label>
                            </div>
                            <div class="form-floating mb-3">
                                <input type="text" id="ville" name="ville" required style={{borderRadius:30}} value={ville} onClick={()=>setModalVisible3(true)} onChange={e =>ChangeVille(e.target.value)} class="form-control" placeholder="floatingPrenom"/>
                                <ListGroup>
                                  {modalVisible3 && dataVille.map((item, index) => (
                                  <ListGroup.Item action variant="" key={index} onClick={()=>{ChangeVille(item.label); setModalVisible3(false)}}>
                                  {item.label}
                                  </ListGroup.Item>
                                ))} 
                                </ListGroup>
                                <label for="ville">Ville *</label>
                            </div>
                            <div class="form-floating mb-3" >
                                <select onChange={e =>changeHandler2(e.target.value)} value={value2} style={{borderRadius:30}} placeholder="Nationalité *" class="form-control" id="floatinggenre">
                                {
                                options.map( (x) => 
                                  <option key={x.value} value={x.value}>{x.label}</option> )
                                }
                                </select>
                                <label for="floatingPrfloatinggenreenom">Pays *</label>
                            </div>
                            <div class="input-group mb-3">
  <div class="input-group-text">
    <input class="form-check-input mt-0" type="checkbox" onChange={(e)=>{handleChange2(e.target.value)}}  checked={checked2}  aria-label="Checkbox for following text input"/>
  </div>
  <Col className="text-center" style={{color:'white',fontSize:15,marginInline:20}}>
                                J'accepte de recevoir la NewsLetter et les informations concernant UserAdventure.
                                    </Col>
</div>
                            <InputGroup className="mb-3">
                            <InputGroup.Checkbox aria-label="Checkbox for following text input" value={checked}
                                onChange={handleChange}/>
                                <Col className="text-center" style={{color:'white',fontSize:15,marginInline:20}}>
                                En cochant cette case, vous acceptez que les informations que vous avez fournies font l’objet d’un traitement informatisé destiné au recrutement des participants aux études.
                                Symplicity UserAdventure est responsable du traitement de vos données personnelles. Vous pouvez exprimer votre souhait de revenir sur votre consentement ou exercer vos droits d’accès, de rectification, d’effacement, d’opposition, de limitation ou de portabilité directement auprès de Symplicity UserAdventure à l’adresse suivante : <LinkContainer onClick={()=>{ window.scrollTo(0, 0)
                                            ChangeActive('#/contact')  
                                         } } style={{margin:0, padding:0, color:'#febf01'}}  exact to={{ pathname:"/contact", state:{ version: '1' }}}>
                                            <a>Useradventure</a>
                                            </LinkContainer>. Les données sollicitées sont nécessaires au traitement de votre demande.
                               
                               
                               
                              
                                            Pour en savoir plus, retrouvez <LinkContainer onClick={()=>{ window.scrollTo(0, 0)
                                           ChangeActive('#/PPDP')
                                          
                                           } } style={{margin:0, padding:0, color:'#febf01'}}  exact to={{ pathname:"/PPDP", state:{ version: '1' }}}>
                                            <a>ici</a>
                                            </LinkContainer> notre politique de protection des données personnelles.
                                    </Col>


                            </InputGroup>
     
                            <Tada>
                            <Row style={{justifyContent:'center',alignItems:'center',marginTop:30}}>
        <Col  className="text-center" >
       
                            <a role="button" class="btn"  onClick={()=>validCommand1()} style={{background:'transparent', color:'white'}} >
                    <Row style={{alignItems:'center'}}>
                        <Col xs="4" style={{zIndex:2}}> 
                            <Image src="https://useradventure.net/images/pigeon.svg"  style={{height:115}}/>
                        </Col>
                        <Col xs="8" style={{zIndex:1}}>
                            <Row style={{background: disabledcolor2, borderRadius:30, paddingBlock:20, paddingLeft:40,paddingRight:15, justifyContent:'center',marginLeft:-30, marginTop:-30, fontWeight:'bold', fontSize:18}}>Valider</Row>
                        </Col>
                    </Row>
              </a> 
              </Col>
                    </Row>

              </Tada>          
                  </div>
        </Col>
        </Container>}
        <Modal3 show={show2} Quit={() => {setShow2(false); history.push("/"); window.scrollTo(0, 0)}} validCommand3={validCommand3}/>
        </div>
    )
}

export default Inscription
