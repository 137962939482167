import React, {useState, useEffect, useRef} from 'react';
import logo from '../images/logo_useradventure.jpg';
import { Container, Row, Col, Image, Button  } from 'react-bootstrap';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import {Fade, Zoom, Flip, Slide} from 'react-reveal';
import { Link , useHistory, NavLink} from "react-router-dom";
import {LinkContainer} from 'react-router-bootstrap';
import $ from 'jquery';

const Menu = ({sendColorbg, sendColor, ActiveRef, ChangeActive}) => {

  const [newValue, setnewValue] = useState('1');
  const [click, setClick] = useState(false);
  const [bg, setbg] = useState("white");
  const [bgSwitch, setbgSwitch] = useState("#4b4b4b");
  const [fontSwitch, setfontSwitch] = useState('white');
  const handleClick = () => setClick(!click);
  const [navcolor, setnavcolor] = useState('light');
  const color1 = "#007F8B";
  const color2 = "#988EFA";
  var largeur = window.innerWidth;
  
  
  console.log('active',ActiveRef)
 


const closeToggle = () =>{
  if (largeur < 1400 ){
  $('.panel-collapse').collapse({
    toggle: false
  });}
}

  return (
      <>
      <div id="topheader">
           <Navbar fixed="top" className="shadow" collapseOnSelect variant={navcolor}   expand="xxl" style={{ background: "white", padding : 0}} >
          
          <Navbar.Brand href="">
          <LinkContainer onClick={()=>{closeToggle()}} style={{paddingRight:80,color: ActiveRef === '/' ?'#007F8B' : 'grey', fontWeight : ActiveRef === '/' ? 'bold' : 'normal'}}  exact to={{ pathname:"/", state:{ version: '1' }}}>
          <Nav.Link >
          <Col>
          <Flip top>
          <Image src={logo} style={{height:65, marginLeft: largeur <600 ? 0:20}} fluid alt="logoUseradventure"/>
          </Flip>
           </Col>
           </Nav.Link>
           </LinkContainer>
         
          </Navbar.Brand>
          <Navbar.Toggle  style={{marginRight:40}}/>
          <Navbar.Collapse  style={{backgroundColor: "white"}} >
        <Nav className="me-auto"  onSelect={(selectedKey) => ChangeActive(selectedKey)} style={{fontSize:22}}>
                <LinkContainer onClick={()=>{closeToggle()  }} style={{paddingBlock:30, paddingInline:30, color: ActiveRef === '/' ? '#007F8B' : 'grey',fontWeight : ActiveRef === '/' ? 'bold' : 'normal'}}  exact to={{ pathname:"/", state:{ version: '1' }}}>
                            <Nav.Link >Accueil</Nav.Link>
                </LinkContainer>
                {/* <LinkContainer onClick={()=>{closeToggle()}} style={{paddingRight:49,color: ActiveRef === '/inscription' ? '#007F8B' : 'grey', fontWeight: ActiveRef === '/inscription' ? 'bold' : 'normal'}}   exact to={{ pathname:"/inscription", state:{ version: '1' }}}>
                    <Nav.Link>S'inscrire</Nav.Link>
                </LinkContainer>  */}
                <LinkContainer onClick={()=>{closeToggle()}} style={{paddingBlock:30, paddingInline:30, color: ActiveRef === '/test' ? '#007F8B' : 'grey',fontWeight : ActiveRef === '/test' ? 'bold' : 'normal'}}   exact to={{ pathname:"/test", state:{ version: '1' }}}>
                    <Nav.Link>Études à venir</Nav.Link>
                </LinkContainer>
                <LinkContainer onClick={()=>{closeToggle()}} style={{paddingBlock:30, paddingInline:30, color: ActiveRef === '/nous' ? '#007F8B' : 'grey',fontWeight : ActiveRef === '/nous'  ? 'bold' : 'normal'}}  exact to={{ pathname:"/nous", state:{ version: '1' }}}>
                            <Nav.Link>Qui sommes-nous ?</Nav.Link>
                </LinkContainer>
                  <LinkContainer onClick={()=>{ closeToggle()}} style={{paddingBlock:30, paddingInline:30, color: ActiveRef === '/contact' ? '#007F8B' : 'grey',fontWeight : ActiveRef === '/contact' ? 'bold' : 'normal'}}   exact to={{ pathname:"/contact", state:{ version: '1' }}}>
                    <Nav.Link>Contact</Nav.Link>
                </LinkContainer>
                
               
               
              </Nav>  
              <Nav>
              <Nav.Link style={{ padding:33}} href="https://useradventurepro.fr ">
              <span style={{fontWeight : 'bold', fontSize:18 }}>Accès Pro</span>
        </Nav.Link>
               
              <LinkContainer onClick={()=>{closeToggle()}} style={{
               backgroundColor:'#febf01', padding:33,color: ActiveRef === '/inscription' ? '#007F8B' : 'grey', fontWeight: ActiveRef === '/inscription' ? 'bold' : 'normal'}}   exact to={{ pathname:"/inscription", state:{ version: '1' }}}>
                    <Nav.Link><span style={{color: '#007F8B',fontWeight : 'bold', fontSize:18 }}>Inscription</span></Nav.Link>
                </LinkContainer> 
                </Nav>
          </Navbar.Collapse>
        
      </Navbar>
      </div>
      </>
  )
}

export default Menu


