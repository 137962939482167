import React, {useState, useMemo, useEffect} from 'react';
import {Fade, Flip, Slide} from 'react-reveal';
import { Container, Row, Col, Image, Ratio, Modal,  Accordion, ListGroup  } from 'react-bootstrap';
import Wave from 'react-wavify'

const PPDP =  ({props, ChangeActive, sendColorbg, sendColor}) => {

  const color1 = "#007F8B";
  const color2 = "#988EFA";
  
  useEffect(()=>{
    sendColorbg(`linear-gradient(to bottom,  ${color1} 0%,${color2} 100%)`)
    sendColor("white") 
    }, [props])


    return (
        <div>
        
        <Container style={{marginTop:50}}   fluid>
      <Fade bottom> 
      <Col className="text-center" style={{fontSize: 30,color: '#007F8B',paddingInline:30, fontWeight:'bold', marginBottom:50 }}>POLITIQUE DE PROTECTION
DE VOS DONNÉES PERSONNELLES</Col>
      </Fade>
      <Fade bottom> 
      <Col className="text-start" style={{fontSize: 20,color: '#007F8B',paddingInline:30, marginBlock:20, fontWeight:'bold'}}>Notre philosophie et nos engagements</Col>
      <p className="text-start" style={{paddingInline:30}}>Symplicity Useradventure s’engage pour la protection de vos données personnelles et se fait fort d'assurer un niveau élevé de protection de vos données personnelles conformément au Règlement européen 2016/679 et la loi informatique et liberté n°78-17.</p>
      <p className="text-start" style={{paddingInline:30 }}>A ce titre, vous trouverez ci-dessous notre politique de protection de vos données personnelles vous expliquant notamment ce que nous collectons comme données personnelles, le traitement qui en est fait et sur quel fondement, leur conservation et vos droits personnels. Nous vous invitons à en prendre connaissance.</p>
      <p className="text-start" style={{paddingInline:30 }}>Notre Délégué à la Protection des Données Personnelles est à votre disposition pour répondre à toutes vos questions, vous pouvez le contacter à l’adresse suivante : maxime.aldegheri@useradventure.fr</p>
      <p className="text-start" style={{paddingInline:30}}>Vous pourrez retrouver le texte du Règlement européen applicable ici : <a href='https://eur-lex.europa.eu/legal-content/FR/TXT/?uri=CELEX%3A32016R0679' target="_blank">https://eur-lex.europa.eu/legal-content/FR/TXT/?uri=CELEX%3A32016R0679</a> ou interroger/saisir l’autorité de régulation (CNIL) via son site internet <a href='https://www.cnil.fr/' target="_blank">www.CNIL.fr</a>.</p>
      <p className="text-start" style={{paddingInline:30}}>La présente version de la politique des données personnelles pourra être modifiée par nos soins le cas échéant et vous en serez informés.</p>
      </Fade>
      <Fade bottom> 
      <Col className="text-start" style={{fontSize: 20,color: '#007F8B',paddingInline:30, marginBlock:20, fontWeight:'bold', marginTop:50}}>Votre responsable de traitement</Col>
      <p className="text-start" style={{paddingInline:30}}>Symplicity Useradventure est le responsable de traitement de vos données personnelles et dont vous trouverez ci-après les coordonnées : 15 RUE AUGUSTE POTIE 59320 EMMERIN ; elle est désignée par son nom ou « Nous » dans les présentes.</p>
      </Fade>
      <Fade bottom> 
      <Col className="text-start" style={{fontSize: 20,color: '#007F8B',paddingInline:30, marginBlock:20, fontWeight:'bold', marginTop:50}}>Vos données personnelles et leur collecte par Symplicity Useradventure</Col>
      <ul className="text-start" style={{listStyle: 'circle inside'}}>Vos données personnelles peuvent être collectées lors de :
        <li style={{paddingInline:60, marginBlock:10}}>votre visite sur notre site,</li>
        <li style={{paddingInline:60, marginBlock:10}}>nos échanges,</li>
        <li style={{paddingInline:60, marginBlock:10}}>nos actions de prospections,</li>
        <li style={{paddingInline:60, marginBlock:10}}>la formation ou exécution de nos contrats.</li>
      </ul>
      <p className="text-start" style={{paddingInline:30}}>Nous ne collectons aucune donnée non-nécessaire à la finalité de traitement mentionnée lors de la collecte ou données prohibées par la loi ou la réglementation.</p>
      <p className="text-start" style={{paddingInline:30}}>La collecte de certaines données peut être obligatoire ou facultative et vous êtes informés des informations obligatoires. Vos données personnelles peuvent être collectées par des tiers prestataires ou partenaires, lesquels s’engagent à être en conformité avec la réglementation européenne et nationale sur les données personnelles.</p>
      <p className="text-start" style={{paddingInline:30}}>Notre politique est de ne pas effectuer de transfert de vos données hors de l’Union européenne ; si par exception nous y procédions, ce transfert ne pourra intervenir qu’auprès d’un pays ou d’une organisation relevant d’une décision d’adéquation (art.45 RGPD) ou présentant des garanties suffisantes appropriées (art.46 RGPD).</p>
      <p className="text-start" style={{paddingInline:30}}>Nous ne prenons aucune décision automatisée.</p>
      <ul className="text-start" style={{listStyle: 'inside'}}>Nous sommes amenés potentiellement à collecter vos données personnelles suivantes :
        <li style={{paddingInline:30, marginTop:15}}>Etat-civil, identité, coordonnées de contact, images</li>
        <li style={{paddingInline:30}}>Vie personnelle</li>
        <li style={{paddingInline:30}}>Vie professionnelle</li>
        <li style={{paddingInline:30}}>Données de connexion</li>
        <li style={{paddingInline:30}}>Internet & téléphonie</li>
      </ul>
      </Fade>
      <Fade bottom> 
      <Col className="text-start" style={{fontSize: 20,color: '#007F8B',paddingInline:30, marginBlock:20, fontWeight:'bold', marginTop:50}}>Nos traitements de vos données personnelles</Col>
      <p className="text-start" style={{paddingInline:30}}>Nous traitons vos données personnelles en les insérant dans les bases de données ; elles sont stockées, conservées et le cas échéant rectifiées, supprimées, archivées, anonymisées ou pseudonymisées, transférées à des tiers de confiance.</p>
      <ul className="text-start" style={{listStyle: 'circle inside',paddingInline:30}}>Nous sommes amenés à traiter vos données personnelles pour les finalités de traitements suivantes ou pour des finalités qui vous sont précisées lors de la collecte :
        <li style={{paddingInline:30, paddingBlock:30,color: '#007F8B',fontWeight:'bold',fontStyle:'italic'}}>Votre information sur nos offres commerciales (produits, services...) et promotionnelles – Communiquer avec vous</li>
        <p className="text-start">Nous sommes susceptibles d’utiliser vos données personnelles à des fins de prospection commerciale, et notamment afin de vous faire parvenir des informations sur nos produits/services, nos offres commerciales et promotionnelles, devis et autres documents pré-contractuels, nos actualités par mail, courrier ou par téléphone.</p>
        <li style={{paddingInline:30, paddingBlock:30,color: '#007F8B',fontWeight:'bold',fontStyle:'italic'}}>L’exécution de vos contrats en cours et le suivi clientèle</li>
        <p className="text-start">Nous utilisons vos données personnelles afin d’assurer l’exécution des contrats en cours conformément à vos demandes. Nous sommes également susceptibles de vous faire parvenir toutes informations sur votre commande ou vos contrats en cours, leur exécution, vos factures et documents contractuels, des conseils, l’exécution de nos garanties le cas échéant et de nos obligations légales. Nous utilisons encore vos données personnelles pour gérer notre relation client, vos demandes ou réclamations, le cas échéant les litiges et suivre votre historique client.</p>
        <li style={{paddingInline:30, paddingBlock:30,color: '#007F8B',fontWeight:'bold',fontStyle:'italic'}}>L’amélioration de l’utilisation de nos services et améliorer nos offres</li>
        <p className="text-start">Nous traitons vos données personnelles pour vous permettre une utilisation optimale de nos services, améliorer nos offres et produits/services, et pour suivre votre parcours utilisateurs, réaliser des enquêtes de satisfaction, sondages et statistiques anonymes.</p>
        <li style={{paddingInline:30, paddingBlock:30,color: '#007F8B',fontWeight:'bold',fontStyle:'italic'}}>Vos paiements</li>
        <p className="text-start">Vos coordonnées bancaires peuvent être collectées soit directement par nos soins soit par un prestataire dédié et sélectionné, lequel garantit l’entière confidentialité de vos données bancaires et ces coordonnées ne sont conservées que pour le temps nécessaire à la durée de la relation contractuelle ou dans les limites légales.</p>
        <li style={{paddingInline:30, paddingBlock:30,color: '#007F8B',fontWeight:'bold',fontStyle:'italic'}}>Protection contre les initiatives frauduleuses</li>
        <p className="text-start">Les données personnelles collectées peuvent être utilisées pour lutter contre la fraude notamment sur les paiements ou prélèvements effectués. A ce titre, nos prestataires en sécurité de paiement peuvent être rendus destinataire de ces données.</p>
        <li style={{paddingInline:30, paddingBlock:30,color: '#007F8B',fontWeight:'bold',fontStyle:'italic'}}>S’assurer du respect du droit et des décisions de justice</li>
        <p className="text-start">Vos Données peuvent être utilisées pour :</p>
          <ul className="text-start" style={{listStyle: 'circle inside',paddingInline:30}}>
            <li>répondre à une demande d’une autorité administrative ou judiciaire, d’un représentant de la loi, d’un auxiliaire de justice ou nous conformer à une décision de justice ;</li>
            <li>s’assurer du respect de nos conditions générales de vente/service ;</li>
            <li>protéger nos droits et/ou obtenir la réparation des dommages que nous pourrions subir ou en limiter les conséquences ;</li>
            <li>prévenir tout agissement contraire aux lois en vigueur, notamment dans le cadre de la prévention des risques de fraude.</li>
          </ul>
        <p className="text-start" style={{paddingTop:30}}>Nous pouvons encore être amenés à traiter vos données personnelles aux fins suivantes :</p> 
          <ul className="text-start" style={{listStyle: 'circle inside',paddingInline:30}}>
              <li>Relation commerciale</li>
              <ul className="text-start" style={{listStyle: 'square inside',paddingInline:30}}>
               <li style={{paddingInline:30}}>Organisation de Jeux concours, Loteries, Opérations promotionnelles, etc.</li>
               <li style={{paddingInline:30}}>Envoi de campagnes marketing par email, courrier ou téléphone (y compris via un prestataire)</li>
               <li style={{paddingInline:30}}>Géolocalisation des utilisateurs / clients / fournisseurs</li>
             </ul>
              <li>Divers</li>
              <ul className="text-start" style={{listStyle: 'square inside',paddingInline:30}}>
               <li style={{paddingInline:30}}>Gestion des réseaux professionnels</li>
             </ul>
              <li>Internet</li>
              <ul className="text-start" style={{listStyle: 'square inside',paddingInline:30}}>
               <li style={{paddingInline:30}}>Créer et gérer votre compte utilisateur</li>
               <li style={{paddingInline:30}}>Ciblage de publicité : Nous utilisons potentiellement vos données pour cibler la publicité sur notre site ou de partenaires en les croisant avec d’autres données.</li>
               <li style={{paddingInline:30}}>Statistiques client et sondages : Certaines données sont traitées à des fins statistiques, notamment pour évaluer et améliorer la performance de nos programmes, ou encore effectuer des mesures d'audience sur notre site. Les données personnelles sont alors anonymisées pour cette finalité.</li>
             </ul>
              <li>Gestion des cookies</li>
              <ul className="text-start" style={{listStyle: 'square inside',paddingInline:30}}>
               <li style={{paddingInline:30}}>Cookies nécessaires — à l’utilisation optimisée du site (par exemples : identification, panier,</li>
               <li style={{paddingInline:30}}>Cookies de performances — (permettant d’établir des statistiques anonymes et niveau de trafic sur le site) et de suivi et de personnalisation collectant des informations sur votre utilisation du site et permettant une individualisation de nos offres,</li>
               <li style={{paddingInline:30}}>Cookies analytiques — nous permettant de comprendre et d’analyser votre navigation sur notre site.</li>
             </ul>
            </ul>
      </ul>
      </Fade>
      <Fade bottom> 
      <Col className="text-start" style={{fontSize: 20,color: '#007F8B',paddingInline:30, marginBlock:20, fontWeight:'bold', marginTop:50}}>Le fondement du traitement de vos données personnelles</Col>
      <ul className="text-start" style={{listStyle: 'circle inside',paddingInline:30}}>Conformément à la réglementation, le traitement de vos données personnelles par nos soins est fondé s’il repose sur l’un des fondements suivants :
            <li style={{paddingInline:60, marginBlock:10}}>Votre consentement au traitement de vos données par nos soins : vous acceptez le traitement de vos données personnelles par le biais d'un consentement exprès. Vous pouvez retirer ce consentement à tout moment auprès de notre DPO ; ou</li>
            <li style={{paddingInline:60, marginBlock:10}}>L’existence d’un contrat entre vous et nous : le traitement des données est alors justifié par les besoins de l’exécution du contrat ; ou</li>
            <li style={{paddingInline:60, marginBlock:10}}>Notre intérêt légitime au traitement de vos données personnelles dès lors que cet intérêt proportionné respecte vos droits fondamentaux et votre vie privée ; ou</li>
            <li style={{paddingInline:60, marginBlock:10}}>La Loi ou la réglementation en vigueur lorsque celle-ci nous oblige au traitement et à la conservation de vos données personnelles.</li>
          </ul>
      </Fade>
      <Fade bottom> 
      <Col className="text-start" style={{fontSize: 20,color: '#007F8B',paddingInline:30, marginBlock:20, fontWeight:'bold', marginTop:50}}>Modalités et durées de conservation de vos données personnelles</Col>
      <ul className="text-start" style={{listStyle: 'circle inside',paddingInline:30}}>Nous gérons vos données personnelles selon trois phases :
            <li style={{paddingInline:60, marginBlock:10}}>une phase active où les données sont conservées pour le temps indiqué ci-dessous en base « active » : vos données personnelles sont alors accessibles uniquement par les personnes ayant un besoin opérationnel à y accéder afin de réaliser les traitements autorisés</li>
            <li style={{paddingInline:60, marginBlock:10}}>une phase d’archivage (pour un temps supplémentaire à la conservation en base « active ») lorsqu’un motif légitime le justifie : vos données personnelles sont alors archivées avec un accès restreint et pour une durée limitée.</li>
            <li style={{paddingInline:60, marginBlock:10}}>Une phase de suppression ou d’anonymisation : à l’issue de l’archivage supplémentaire dans les délais ci- dessous, vos données personnelles sont supprimées ou anonymisées (de telle sorte qu’elles ne peuvent plus constituer une donnée personnelles vous identifiant).</li>
          </ul>
       <p className="text-start" style={{paddingInline:30}}>Vos données personnelles sont conservées pour le temps nécessaire aux besoins de leur traitement, de notre relation client le cas échéant et l’exécution des contrats et dans les limites spécifiquement édictées réglementairement ; nous sommes susceptibles de conserver vos données personnelles en archivage pour les besoins de conservation d’éléments justificatifs comptables, fiscaux ou probatoires le temps des prescriptions applicables. A titre d’exemple, nous vous indiquons ci-après les durées de conservation s’appliquant aux traitements suivants (sous réserve de la réglementation imposant un temps de conservation différencié) :</p>
       <div class="table-responsive-md" style={{overflow :'scroll'}}>
  <table class="table table-bordered">
    <thead>
        <tr>
            <th style={{textAlign:'center'}}>Finalité du traitement</th>
            <th style={{textAlign:'center'}}>Fondement du traitement</th>
            <th style={{textAlign:'center'}}>Conservation de la donnée personnelle dans la base « active »</th>
            <th style={{textAlign:'center'}}>Archivage supplémentaire</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td style={{textAlign:'center'}}>Prospection</td>
            <td style={{textAlign:'center'}}>Votre consentement</td>
            <td style={{textAlign:'center'}}>3 ans si vous n'avez répondu activement à aucune sollicitation. Le délai court à nouveau en cas de sollicitation active de votre part.</td>
            <td style={{textAlign:'center'}}>X</td>
        </tr>
        <tr>
            <td style={{textAlign:'center'}}>Exécution de nos obligations contractuelles à votre égard / services</td>
            <td style={{textAlign:'center'}}>Contrat</td>
            <td style={{textAlign:'center'}}>Le temps nécessaire à l’exécution du contrat et 3 ans à compter de la fin de la relation commerciale (dernière activité (telle que fin d’exécution de contrat (achat, service...), connexion sur le site en tant qu’utilisateur inscrit)</td>
            <td style={{textAlign:'center'}}>5 ans à l’issue de la relation contractuelle</td>
        </tr>
        <tr>
            <td style={{textAlign:'center'}}>Relation client</td>
            <td style={{textAlign:'center'}}>Contrat</td>
            <td style={{textAlign:'center'}}>3 ans à compter de la fin de la relation commerciale (dernière activité de votre part auprès de nous)</td>
            <td style={{textAlign:'center'}}>5 ans à l’issue de la relation contractuelle</td>
        </tr>
    </tbody>
    </table>
</div>
      </Fade>
      <Fade bottom> 
      <Col className="text-start" style={{fontSize: 20,color: '#007F8B',paddingInline:30, marginBlock:20, fontWeight:'bold', marginTop:50}}>Retrait de votre consentement à la collecte ou au traitement de vos données personnelles</Col>
      <p className="text-start" style={{paddingInline:30}}>Votre consentement accordé pour la collecte de vos données personnelles peut être retiré en écrivant à notre DPO par email ou par courrier aux adresses figurant en-tête en mentionnant votre nom, prénom, e-mail et adresse avec la nature et l’objet précis de votre demande de retrait.</p>
      <p className="text-start" style={{paddingInline:30}}>Vous pouvez également nous adresser tout commentaire sur vos données personnelles à Symplicity Useradventure 15 RUE AUGUSTE POTIE 59320 EMMERIN</p>
      </Fade>
     
      <Fade bottom> 
      <Col className="text-start" style={{fontSize: 20,color: '#007F8B',paddingInline:30, marginBlock:20, fontWeight:'bold', marginTop:50}}>L’exercice de vos droits sur vos données personnelles</Col>
          <ul className="text-start" style={{listStyle: 'circle inside'}}>Vous disposez :
            <li style={{paddingInline:60,marginBlock:10}}>D’un <strong>droit d’accès</strong>, qui vous permet d’obtenir :</li>
            <ul className="text-start" style={{listStyle: 'square inside',paddingInline:30}}>
               <li style={{paddingInline:60,marginBlock:10}}>La confirmation que des données vous concernant sont ou ne sont pas traitées ;</li>
               <li style={{paddingInline:60,marginBlock:10}}>La communication d’une copie de l’ensemble des données personnelles détenues par le responsable de traitement.</li>
             </ul>
            <li style={{paddingInline:60,marginBlock:10}}>D'un <strong>droit de demander la portabilité</strong> de certaines données: il vous permet de récupérer vos données personnelles dans un format structuré, couramment utilisé et lisible par une machine.</li>
            <li style={{paddingInline:60,marginBlock:10}}>D’un <strong>droit d’opposition</strong> : il vous permet de ne plus faire l’objet de prospection commerciale de notre part ou de nos partenaires, ou, pour des raisons tenant à votre situation particulière, de faire cesser le traitement de vos données à des fins de recherche et développement, de lutte contre la fraude et de prévention.</li>
            <li style={{paddingInline:60,marginBlock:10}}>D’un <strong>droit de rectification</strong> : il vous permet de faire rectifier une information vous concernant lorsque celle-ci est obsolète ou erronée. Il vous permet également de faire compléter des informations incomplètes vous concernant.</li>
            <li style={{paddingInline:60,marginBlock:10}}>D’un <strong>droit d’effacement</strong> : il vous permet d’obtenir l’effacement de vos données personnelles sous réserve des durées légales de conservation. Il peut notamment trouver à s’appliquer dans le cas où vos données ne seraient plus nécessaires au traitement.</li>
            <li style={{paddingInline:60,marginBlock:10}}>D’un <strong>droit de limitation</strong> : Il vous permet de limiter le traitement de vos données dans les cas suivants :</li>
            <ul className="text-start" style={{listStyle: 'square inside',paddingInline:30}}>
               <li style={{paddingInline:60,marginBlock:10}}>En cas d’usage illicite de vos données ;</li>
               <li style={{paddingInline:60,marginBlock:10}}>Si vous contestez l’exactitude de vos celles-ci ;</li>
               <li style={{paddingInline:60,marginBlock:10}}>S'il vous est nécessaire de disposer des données pour constater, exercer ou défendre vos droits.</li>
             </ul>
             <p className="text-start" style={{paddingInline:30, marginTop:40}}>Elles ne feront alors plus l’objet d’un traitement actif, et ne pourront pas être modifiées pendant la durée de l’exercice de ce droit.</p>
             <li style={{paddingInline:60}}>D’un <strong>droit d’obtenir une intervention humaine</strong> : les responsables de traitement peuvent avoir recours à une prise de décision automatisée en vue de la souscription ou de la gestion de votre contrat. Dans ce cas, vous pouvez demander quels ont été les critères déterminants de la décision auprès du Délégué à la Protection des Données.</li>
          </ul>
      <p className="text-start" style={{paddingInline:30}}>Vous pouvez exercer ces droits par courrier électronique : maxime.aldegheri@useradventure.fr ou par lettre à l’adresse suivante : 15 RUE AUGUSTE POTIE 59320 EMMERIN en indiquant votre nom, prénom, domicile et e-mail (le cas échéant vos références client) ainsi que l’objet de votre demande dans des termes clairs et lisibles. Symplicity Useradventure s’engage à donner suite à votre demande vérifiée sous un délai d’un mois à compter de sa réception.</p>
      <p className="text-start" style={{paddingInline:30}}>En cas de difficulté, vous pouvez vous adresser directement à notre délégué à la protection des données personnelles directement par mail : maxime.aldegheri@useradventure.fr ou saisir la Commission Nationale de l'Informatique et des Libertés (CNIL).</p>
      </Fade>
      <Fade bottom> 
      <Col className="text-start" style={{fontSize: 20,color: '#007F8B',paddingInline:30, marginBlock:20, fontWeight:'bold', marginTop:50}}>Nos sous-traitants et partenaires</Col>
      <p className="text-start" style={{paddingInline:30}}>Symplicity Useradventure est susceptible de transmettre vos données personnelles à des sous-traitants réalisant des prestations comportant des traitements de vos données et dans le respect des finalités visées aux présentes ; ces sous- traitants doivent conférer à vos données personnelles le même niveau de confidentialité que Symplicity Useradventure et se sont engagés à être en parfaite conformité avec la réglementation sur les données personnelles, notamment avec le RGPD.</p>
      <p className="text-start" style={{paddingInline:30}}>Nous ne faisons aucun commerce de vos données personnelles ; si vous souhaitez en savoir plus et connaitre spécifiquement l’identité des prestataires ou partenaires à qui vos données personnelles ont été transmises, vous pouvez contacter notre DPO à l’adresse suivante : maxime.aldegheri@useradventure.fr</p>
      <ul className="text-start" style={{listStyle: 'circle inside',paddingInline:30}}>Les prestataires ou partenaires susceptibles d’accéder à vos données personnelles peuvent notamment être :
            <li style={{paddingInline:60,marginBlock:10}}>les prestataires susceptibles de gérer les services externalisés pour l’exécution de nos services et contrats ;</li>
            <li style={{paddingInline:60,marginBlock:10}}>les prestataires nous aidant à améliorer nos services, procéder aux analyses de données et optimiser nos offres, effectuer des sondages et statistiques ;</li>
            <li style={{paddingInline:60,marginBlock:10}}>les commissaires aux comptes, experts comptables, consultants, avocats, cabinet d’audit, prestataires informatiques et infogérance, prestataires de sécurité ;</li>
            <li style={{paddingInline:60,marginBlock:10}}>les investisseurs et repreneurs.</li>
          </ul>
      <p className="text-start" style={{paddingInline:30}}>Nous pouvons également être amenés à transmettre vos données personnelles aux autorités, administrations et juridictions françaises notamment dans le cadre d’une action judiciaire ou de formalités légales nécessitant cette communication.</p>
      </Fade>
      <Fade bottom> 
      <Col className="text-start" style={{fontSize: 20,color: '#007F8B',paddingInline:30, marginBlock:20, fontWeight:'bold', marginTop:50}}>Politique des cookies</Col>
      <Col className="text-start" style={{fontSize: 20,color: '#007F8B',paddingInline:30, marginBlock:20, fontWeight:'bold'}}>Qu'est-ce qu'un cookie ?</Col>
      <p className="text-start" style={{paddingInline:30}}>Un cookie est un fichier déposé sur votre terminal (ordinateur, tablette par ex.) par Symplicity Useradventure lors de votre utilisation du site internet et en fonction de votre navigateur. Ce fichier permettra pendant sa durée d’enregistrement d’identifier votre ordinateur lors de vos prochaines visites sur le site, nous permettant de conserver vos données de visites et de navigation.</p>
      <p className="text-start" style={{paddingInline:30}}>Symplicity Useradventure est susceptible d’utiliser divers systèmes de collecte de données personnelles notamment par voie de cookies sur notre site internet pour lesquels votre consentement est sollicité ; sont potentiellement concernés les cookies suivants :</p>
      <p className="text-start" style={{paddingInline:30}}>Symplicity Useradventure est également susceptible d'émettre des cookies de tiers permettant le partage des contenus de notre site avec des tiers ou des outils d’expression de votre appréciation (ex : "J'aime", issus de réseaux sociaux) ; vous êtes alors potentiellement identifiés par le réseau social qui pourra suivre votre navigation. Il vous appartient de vous informer de sa politique de confidentialité et de gestion des cookies, notre société étant étrangère à ces outils.</p>
      <p className="text-start" style={{paddingInline:30}}>Les cookies sont soumis à votre acceptation sur notre site internet lors de votre première visite. La durée de validité du consentement au dépôt des cookies et traceurs est de 13 mois maximum, à compter de leur premier dépôt dans votre terminal faisant suite à l’expression de votre consentement.</p>
      <p className="text-start" style={{paddingInline:30}}>Vous pouvez gérer votre acceptation ou refus de cookies directement depuis les paramètres de votre navigateur.</p>
      <p className="text-start" style={{paddingInline:30}}>Vous pouvez, ainsi, soit accepter tous les cookies, soit être averti lorsqu’un cookie est placé, soit refuser tous les cookies. Si vous refusez tout ou partie les cookies, certaines fonctionnalités du Site pourraient être compromises ou certaines pages inaccessibles.</p>
      <p className="text-start" style={{paddingInline:30}}>Pour désactiver les cookies :</p>
      <ol className="text-start" >Si vous utilisez Internet Explorer 8. et suivants :
            <li style={{marginInline:60}}>Allez à « Outils » dans la barre de menu et cliquez sur « Options Internet »</li>
            <li style={{marginInline:60}}>Cliquez sur l’onglet « Confidentialité » en haut</li>
            <li style={{marginInline:60}}>Faites glisser le curseur vers le haut jusqu’au paramètre « Bloquer tous les cookies » pour bloquer tous les
cookies,ou vers le bas jusqu’au paramètre « Accepter tous les cookies » pour accepter tous les cookies.</li>
          </ol>
      <p className="text-start" style={{paddingInline:30}}>Pour de plus amples informations, consultez <a href='http://windows.microsoft.com/fr-fr/internet-explorer/delete-manage-cookies' target="_blank">http://windows.microsoft.com/fr-fr/internet-explorer/delete-manage-cookies</a></p>
      <ol className="text-start">Si vous utilisez Firefox 30.0 et suivants :
            <li style={{marginInline:60}}>Cliquez sur le bouton « menu » et sélectionnez « Options »</li>
            <li style={{marginInline:60}}>Sélectionnez le panneau « Vie privée ».</li>
            <li style={{marginInline:60}}>Dans la zone Historique, pour l'option « Règles de conservation », sélectionnez « utiliser les paramètres
personnalisés pour l'historique ».</li>
            <li style={{marginInline:60}}>Cochez la case « Accepter les cookies » pour activer les cookies, ou décochez-la pour les désactiver. Si vous avez des problèmes avec les cookies, assurez-vous que l'option « Accepter les cookies tiers » ne soit
pas positionnée sur Jamais.</li>
<li style={{marginInline:60}}>Choisissez combien de temps les cookies peuvent être conservés.
Les conserver jusqu'à : « Leur expiration » : Chaque cookie sera supprimé à sa date d'expiration, date fixée par le site émetteur du cookie.
Les conserver jusqu'à : « La fermeture de Firefox » : les cookies enregistrés sur votre ordinateur seront supprimés lorsque vous fermerez Firefox.
Les conserver jusqu'à : « Me demander à chaque fois » : un avertissement s'affiche chaque fois qu'un site web
souhaite envoyer un cookie, vous demandant si vous acceptez d'enregistrer le cookie ou pas.</li>
<li style={{marginInline:60}}>Cliquez sur OK pour fermer la fenêtre « Options »</li>
          </ol>
          <p className="text-start" style={{paddingInline:30}}>Pour de plus amples informations, consultez <a href='https://support.mozilla.org/fr/products/firefox/privacy-and-security/cookies' target="_blank">https://support.mozilla.org/fr/<br/>products/firefox/privacy-and-security/cookies</a></p>
          <ol className="text-start" >Si vous utilisez Google Chrome :
            <li style={{marginInline:60}}>Allez au menu « Outils »</li>
            <li style={{marginInline:60}}>Cliquez sur « Paramètres »</li>
            <li style={{marginInline:60}}>Cliquez sur « Paramètres avancés »</li>
            <li style={{marginInline:60}}>Cliquez sur « Confidentialité/Paramètres de contenu »</li>
            <li style={{marginInline:60}}>« Cookies » doit être sélectionné. Sélectionnez ensuite « Bloquer les cookies et données de sites tiers »</li>
          </ol>
          <p className="text-start" style={{paddingInline:30}}>Pour de plus amples informations, consultez <a href='https://support.google.com/chrome/answer/95647?hl=fr' target="_blank">https://support.google.com<br/>/chrome/answer/95647?hl=fr</a></p>
          <ol className="text-start">Si vous utilisez Safari 5.0 et suivants :
            <li style={{marginInline:60}}>Choisissez Safari / Préférences et cliquez sur « Sécurité ».</li>
            <li style={{marginInline:60}}>Dans la section « Accepter les cookies », indiquez si et quand Safari doit accepter les cookies de sites web. Pour
voir une explication des options, cliquez sur le bouton de l’aide (ressemble à un point d’interrogation). Si vous avez paramétré Safari pour qu’il bloque les cookies, vous devrez peut-être accepter temporairement les cookies pour ouvrir une page. Répétez les étapes ci-dessus, en sélectionnant « Toujours ». Lorsque vous en avez terminé avec la page, désactivez de nouveau les cookies et supprimez les cookies de la page.</li>
          </ol>
          <p className="text-start" style={{paddingInline:30}}>Pour de plus amples informations, consultez <a href='http://support.apple.com/kb/ht1677?viewlocale=fr_FR' target="_blank">http://support.apple.com/kb/ht1677?viewlocale=fr_FR</a></p>
          <p className="text-start" style={{paddingInline:30}}>Si vous avez un type ou une version de navigateur différent, vous êtes invité à consulter le menu « Aide » de votre navigateur.</p>
      </Fade>
    </Container>
        
       
        <Wave fill='#007F8B' style={{paddingInline:0, marginInline:0}} 
        paused={false}
        options={{
          height: 40,
          amplitude: 70,
          speed: 0.2,
          points: 2
        }}
  />
        </div>
    )
}

export default PPDP
