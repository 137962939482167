import React from 'react'
import { Container, Row, Col, Image, ListGroup } from 'react-bootstrap';
import {BsFacebook, BsInstagram, BsTwitter , BsYoutube} from "react-icons/bs";
import { FaChevronUp } from "react-icons/fa";
import {BrowserView, MobileView, isMobile, isIOS, isAndroid} from 'react-device-detect';
import {LinkContainer} from 'react-router-bootstrap';
import Nav from 'react-bootstrap/Nav';



const Footer = ({colorbg, color, ChangeActive, sendColorbg, sendColor, setShow3, setOeuf, Active}) => {

console.log('ActiveRef',Active)
  const color1 = "#007F8B";
  const color2 = "#988EFA";
  

        return ( <Container style={{background: colorbg,paddingTop:30}}  fluid>
            <Row className="justify-content-md-center" style={{marginTop:0}}>
            <Col md="3"  style={{display:'flex', justifyContent:'center'}}>
              <ListGroup style={{borderWidth:0, paddingBottom:60}}>
              <ListGroup.Item style={{fontWeight:'bold',borderWidth:0,fontSize: 20, backgroundColor:'transparent', color:color}}>A propos</ListGroup.Item>
              <ListGroup.Item style={{borderWidth:0, backgroundColor:'transparent', color:color}}>
                <LinkContainer onClick={()=>{ window.scrollTo(0, 0)
                ChangeActive('/nous')
               }} style={{margin:0, padding:0, color:color}}  exact to={{ pathname:"/nous", state:{ version: '1' }}}>
                  <Nav.Link>Qui sommes-nous ?</Nav.Link>
                </LinkContainer></ListGroup.Item>
              <ListGroup.Item style={{borderWidth:0, backgroundColor:'transparent', color:color, display:'none'}}>Blog</ListGroup.Item>
              <ListGroup.Item style={{borderWidth:0, backgroundColor:'transparent', color:color}}>
                <LinkContainer onClick={()=>{ window.scrollTo(0, 0)
                 ChangeActive('/contact')
                }} style={{margin:0, padding:0, color:color}}  exact to={{ pathname:"/contact", state:{ version: '1' }}}>
                  <Nav.Link>Nous contacter</Nav.Link>
                </LinkContainer></ListGroup.Item>
            </ListGroup>
            </Col>
            <Col md="3"  style={{display:'flex', justifyContent:'center'}} >
            <ListGroup style={{ paddingBottom:60}}>
            <ListGroup.Item style={{fontWeight:'bold',fontSize: 20,borderWidth:0, backgroundColor:'transparent', color:color}}>Informations</ListGroup.Item>
              <ListGroup.Item style={{borderWidth:0, backgroundColor:'transparent', color:color, display:'none'}}>
                <LinkContainer onClick={()=>{ window.scrollTo(0, 0)
                 ChangeActive('/faq')
                }} style={{margin:0, padding:0, color:color}}  exact to={{ pathname:"/faq", state:{ version: '1' }}}>
                  <Nav.Link>F.A.Q.</Nav.Link>
                </LinkContainer></ListGroup.Item>
              <ListGroup.Item style={{borderWidth:0, backgroundColor:'transparent', color:color}}> <LinkContainer onClick={()=>{ window.scrollTo(0, 0)
                 ChangeActive('/mention')
                 }} style={{margin:0, padding:0, color:color}}  exact to={{ pathname:"/mention", state:{ version: '1' }}}>
                  <Nav.Link>Mentions légales / CGU</Nav.Link>
                </LinkContainer></ListGroup.Item>
                <ListGroup.Item style={{borderWidth:0, backgroundColor:'transparent', color:color}}> <LinkContainer onClick={()=>{ window.scrollTo(0, 0)
                 ChangeActive('/PPDP')  
                 }} style={{margin:0, padding:0, color:color}}  exact to={{ pathname:"/PPDP", state:{ version: '1' }}}>
                  <Nav.Link>Politique de protection<br/> des données personnelles</Nav.Link>
                </LinkContainer></ListGroup.Item>
            </ListGroup>
            </Col>
            <Col md="3"  style={{display:'flex', justifyContent:'center'}} >
            <ListGroup style={{ paddingBottom:60}}>
              <ListGroup.Item style={{fontWeight:'bold',fontSize: 20,borderWidth:0, backgroundColor:'transparent', color:color}}>Suivez-nous sur les réseaux</ListGroup.Item>
            {
              !isMobile &&
           
            <BrowserView>
            <ListGroup.Item style={{fontSize:30,display:'flex',justifyContent:'space-around',borderWidth:0, backgroundColor:'transparent', color:color}}>
              <BsFacebook onClick={() =>{window.open("https://www.facebook.com/UserAdventure-100727149117995/", '_blank')}} style={{cursor:'pointer'}}/>
              <BsInstagram onClick={() =>{window.open("https://www.instagram.com/useradventurefrance/", '_blank')}} style={{cursor:'pointer'}} />
              <BsTwitter onClick={() =>{window.open("https://twitter.com/UserAdventureFR", '_blank')}} style={{cursor:'pointer'}} />
              <BsYoutube onClick={() =>{window.open("https://www.youtube.com/channel/UCfD1G2iLQvYxjDX-XVSewvw", '_blank')}} style={{cursor:'pointer'}}/>
            </ListGroup.Item>
            </BrowserView>  }
            <MobileView>
            {isIOS &&
            <ListGroup.Item style={{fontSize:30,display:'flex',justifyContent:'space-around',borderWidth:0, backgroundColor:'transparent', color:color}}>
              <BsFacebook onClick={() =>{window.location.href="fb://profile/100727149117995"}} style={{cursor:'pointer'}}/>
              <BsInstagram onClick={() =>{window.open("https://www.instagram.com/useradventurefrance/", '_blank')}} style={{cursor:'pointer'}} />
              <BsTwitter onClick={() =>{window.open("https://twitter.com/UserAdventureFR", '_blank')}} style={{cursor:'pointer'}} />
              <BsYoutube  onClick={() =>{window.location.href="youtube://channel/UCfD1G2iLQvYxjDX-XVSewvw"}} style={{cursor:'pointer'}}/>
              </ListGroup.Item>}
              {isAndroid &&
            <ListGroup.Item style={{fontSize:30,display:'flex',justifyContent:'space-around',borderWidth:0, backgroundColor:'transparent', color:color}}>
              <BsFacebook onClick={() =>{window.location.href="fb://page/100727149117995"}} style={{cursor:'pointer'}}/>
              <BsInstagram onClick={() =>{window.open("https://www.instagram.com/useradventurefrance/", '_blank')}} style={{cursor:'pointer'}} />
              <BsTwitter onClick={() =>{window.open("https://twitter.com/UserAdventureFR", '_blank')}} style={{cursor:'pointer'}} />
              <BsYoutube onClick={() =>{window.open("https://www.youtube.com/channel/UCfD1G2iLQvYxjDX-XVSewvw", '_blank')}} style={{cursor:'pointer'}}/>
              </ListGroup.Item>}
            </MobileView>
            </ListGroup>
            </Col>
          </Row>
        <a onClick={()=>{ window.scrollTo(0, 0)}} class="flash">
            <Row style={{justifyContent:'center',alignItems:'center'}}>
              <FaChevronUp style={{fontSize:30, color:"#007F8B"}}/></Row>
              <Image src="https://useradventure.net/images/pigeon.svg"  style={{height:90}}/>
            </a>
           
            </Container>
        )
    }


export default Footer
